import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Reveal from "react-reveal/Reveal"
import { useStaticQuery, graphql } from "gatsby"
import BlogGridItem from "../../components/BlogGridItem"

const IntegrationsPage = () => {
  const data = useStaticQuery(graphql`
    query IntegrationsQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/integrations/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              title
              path
              image
              day: date(formatString: "DD")
              month: date(formatString: "MMM")
            }
          }
        }
      }
    }
  `)

  return (
    <Layout navClass="w_menu" headerClass="transparent_header">
      <SEO title="Integrations" />
      <section className="breadcrumb_area">
        <img
          className="breadcrumb_shap"
          src={require("../../images/breadcrumb/banner_bg.png")}
          alt=""
        />
        <div className="container">
          <div className="breadcrumb_content text-center">
            <Reveal effect="fadeInUp">
              <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
                Integrations
              </h1>
            </Reveal>
            <p className="f_400 w_color f_size_16 l_height26">
              New integrations are added on a regular basis. Check back here to
              see the latest ones added.
            </p>
          </div>
        </div>
      </section>
      <section className="blog_area_two sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 blog_grid_info">
              <div className="row">
                {data.allMarkdownRemark.edges.map(edge => (
                  <BlogGridItem
                    key={edge.node.id}
                    Title={edge.node.frontmatter.title}
                    description={edge.node.excerpt}
                    image={edge.node.frontmatter.image}
                    url={edge.node.frontmatter.path}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IntegrationsPage
